<template>
  <div class="container">
    <div class="row">
      <div class="col-md-3 align-self-center">
        <h1 class="mb-3">Quem Somos</h1>
      </div>
     <div class="col-md-8 my-3">
        <p>
             

        </p>
        <hr>
        <p class="mt-4"> </p>
        <a class="btn btn-link " href="tel:+55143235-0900">
          <!-- <i class="fa fa-phone fa-fw mx-1 fa-lg"></i> -->
          </a><br>
        <a class="btn btn-link " href="mailto:contato@concilig.com.br" target="_blank">
          <!-- <i class="fa fa-envelope-o fa-fw mx-1 fa-lg"></i> -->
          </a>
      </div>
    </div>
  </div>
  
</template>

<script>

export default {
    name: "Termos",
     components: {
    },
    data: () => ({
        links: [
        ]
    }),
    methods: {
    },
    async created() {
        
    },
    computed: {
    }
};
</script>
